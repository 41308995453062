import React, { Component } from "react";
import WS from "../services/webservice";
import CadastroHelper from "../helper/CadastroHelper.js";
import {
  OverlayTrigger,
  Popover,
  Image,
  Checkbox,
  Modal,
  Breadcrumb,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
} from "react-bootstrap";
const { versao, url } = require("../go-versao.js");

class Central extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sublistas: [],
      record: {},
      pageControl: 0,
    };
    this.ws = new WS();
    this.sendemail = this.sendemail.bind(this);
    this.enviarEmail = this.enviarEmail.bind(this);
    this.confirmarSMS = this.confirmarSMS.bind(this);
    this.renderTonggle0 = this.renderTonggle0.bind(this);
    this.renderTonggle1 = this.renderTonggle1.bind(this);
    this.renderTonggle2 = this.renderTonggle2.bind(this);
    this.renderTonggle3 = this.renderTonggle3.bind(this);
    this.organizado = this.organizado.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  sendemail() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          fontWeight: "bold",
        }}>
        <Button onClick={this.enviarEmail}>Enviar Email</Button>
        <Button onClick={this.confirmarSMS}>Confirmar SMS</Button>
      </div>
    );
  }

  async enviarEmail() {
    console.log(this.state.record.pgseguro_app_email);
    console.log(this.state.record.pgseguro_app_sms);

    let r = await this.ws.sendSolicitarEmail(this.state.record.pgseguro_app_email);
    console.log(r);
    if (r) {
      window.alert("Finalizado");
    } else {
      window.alert("ERRO");
    }
  }

  async confirmarSMS() {
    let r = await this.ws.sendConfirmarEmail(this.state.record.pgseguro_app_email, this.state.record.pgseguro_app_sms);
    if (r) {
      window.alert("Finalizado");
    } else {
      window.alert("ERRO");
    }
  }

  componentDidMount() {}

  async createIugu(m, after) {
    if (window.confirm("Voce vai criar uma configuracao do pag seguro para essa central, isso não tem retorno, tem certeza?")) {
      let retorno = this.ws.createPgSeguro(m.id);
      if (retorno) {
        alert("Finalizado");
      } else {
        alert("ERRO");
      }
    }
    if (after) {
      after();
    }
  }

  renderTonggle2() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={() => this.setState({ pageControl: 2 })}>
        Appm
      </div>
    );
  }

  renderTonggle3() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={() => this.setState({ pageControl: 3 })}>
        Appc
      </div>
    );
  }

  renderTonggle0() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={() => this.setState({ pageControl: 0 })}>
        Cadastro
      </div>
    );
  }

  renderTonggle1() {
    return (
      <div
        style={{
          border: "1.5px solid #000",
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "bold",
        }}
        onClick={() => this.setState({ pageControl: 1 })}>
        Portal
      </div>
    );
  }

  async playSound(file) {
    console.log("playSound", url + "/static/audios/" + file);
    console.log("playSound", this.state);

    let audio = new Audio(url + "/static/audios/" + file);
    audio.play();
  }
  ShowPlaySound(file) {
    return (
      <div>
        <Button onClick={() => this.playSound(file)}>Ouvir</Button>
      </div>
    );
  }

  organizado() {
    let saida = [
      [{ ref: "linha", tipo: "hr" }],
      [
        { ref: "tonggleFotos0", tipo: "fct", function: this.renderTonggle0 },
        { ref: "tonggleFotos1", tipo: "fct", function: this.renderTonggle1 },
        { ref: "tonggleFotos2", tipo: "fct", function: this.renderTonggle2 },
        { ref: "tonggleFotos2", tipo: "fct", function: this.renderTonggle3 },
      ],
      [{ ref: "linha", tipo: "hr" }],
    ];

    if (this.state.pageControl === 0) {
      saida.push(
        ...[
          [
            { ref: "id", tipo: "campo" },
            { ref: "cnpj", tipo: "campo" },
          ],
          [
            { ref: "razaosocial", tipo: "campo" },
            { ref: "nomefantasia", tipo: "campo" },
          ],
          [{ ref: "linha", tipo: "hr" }],
          [{ ref: "subdominio", tipo: "campo" }],
          [{ ref: "senhapadrao", tipo: "campo" }],
          [{ ref: "telefone", tipo: "campo" }],
          [
            { ref: "antecipar", tipo: "campo" },
            { ref: "tempolimite", tipo: "campo" },
          ],
          [
            { ref: "ativo", tipo: "campo" },
            { ref: "encomendas", tipo: "campo" },
          ],
          [{ ref: "transporte", tipo: "campo" }],
          [{ ref: "linha", tipo: "hr" }],
          [
            { ref: "valor_franquia", tipo: "campo" },
            { ref: "corridas_franquia", tipo: "campo" },
          ],
          [
            { ref: "extra_franquia", tipo: "campo" },
            { ref: "percent_franquia", tipo: "campo" },
          ],
          [
            { ref: "vencimento", tipo: "campo" },
            { ref: "fechamento", tipo: "campo" },
          ],
          [{ ref: "linha", tipo: "hr" }],
          [
            { ref: "banco", tipo: "campo" },
            { ref: "tipoconta", tipo: "campo" },
          ],
          [
            { ref: "numeroagencia", tipo: "campo" },
            { ref: "numeroconta", tipo: "campo" },
          ],
          [
            { ref: "pix", tipo: "campo" },
            { ref: "chave", tipo: "campo" },
          ],
          [{ ref: "linha", tipo: "hr" }],
          [{ ref: "tipo_gateway", tipo: "campo" }],
          [{ ref: "efichavepix", tipo: "campo" }],
          [{ ref: "pgseguro_app_email", tipo: "campo" }],
          [{ ref: "pgseguro_app_sms", tipo: "campo" }],
          [{ ref: "pgseguro_verifica", tipo: "fct", function: this.sendemail }],
          [{ ref: "linha", tipo: "hr" }],
          [{ ref: "whatsapp", tipo: "campo" }],
          [{ ref: "linha", tipo: "hr" }],
          [{ ref: "endereco", tipo: "campo" }],
        ]
      );
    }

    if (this.state.pageControl === 1) {
      saida.push(
        ...[
          [{ ref: "linha", tipo: "hr" }],
          [
            {
              ref: "aplicativocor",
              tipo: "campo",
            },
          ],
          [{ ref: "linha", tipo: "hr" }],
          [
            { ref: "obs_field_label", tipo: "campo" },
            { ref: "obs_field_label_plural", tipo: "campo" },
          ],
          [{ ref: "num_maximo_carros", tipo: "campo" }],
          [{ ref: "linha", tipo: "hr" }],
          [{ ref: "appicon", tipo: "campo" }],
          [{ ref: "svgportal", tipo: "campo" }],
          [{ ref: "svgbackground", tipo: "campo" }],
        ]
      );
    }

    if (this.state.pageControl === 2) {
      saida.push(
        ...[
          [{ ref: "linha", tipo: "hr" }],
          [{ ref: "audio", tipo: "campo" }],
          [
            {
              ref: "appmcor",
              tipo: "campo",
            },
          ],
          [
            {
              ref: "driverjson",
              tipo: "campo",
            },
          ],
        ]
      );
    }
    if (this.state.pageControl === 3) {
      saida.push(
        ...[
          [{ ref: "linha", tipo: "hr" }],
          [
            {
              ref: "appccor",
              tipo: "campo",
            },
          ],
          [
            {
              ref: "clientjson",
              tipo: "campo",
            },
          ],
        ]
      );
    }

    saida.push(...[[{ ref: "linha", tipo: "hr" }]]);
    return saida;
  }

  mirrorState(key) {
    console.log("mirrorState", key);
    this.setState(key);
  }

  render() {
    return (
      <CadastroHelper
        dados={{
          acessoID: "f505b54e-de7a-4538-9eb4-b3a28bd9deaa", //! colocar numa variavel de ambiente
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Central",
          tipo_plural: "centrais",
          href: "centrais",
          titulo: "Cadastro de Centrais",
          mirrorState: ((key) => {
            this.setState(key);
          }).bind(this),
          requests: {
            type: "centrais",
            new: true,
            list: true,
            save: true,
            edit: true,
            delete: true,
            listSub: true,
          },
          subtipos: [
            { tipo: "tabelabancos", id: "id", txt: "nomeextenco", p: "get" },
            { tipo: "gateway",
            p: "oarray",
            array: [
              { txt: "PagSeguro", id: 0 },
              { txt: "Efi", id: 1 },
            ],},
            {
              tipo: "tipoconta",
              p: "oarray",
              array: [
                { txt: "Corrente", id: 0 },
                { txt: "Poupança", id: 1 },
              ],
            },
            {
              tipo: "pix",
              p: "array",
              array: ["CPF", "CNPJ", "Telefone", "E-mail", "Aleatória"],
            },
            {
              tipo: "audio",
              p: "array",
              array: [
                "corrida_pendente1.mp3",
                "disconectado.mp3",
                "honk.mp3",
                "mensagemspeedlog.mp3",
                "message.mp3",
                "musiquinha.mp3",
                "novacorrida.mp3",
                "novaentrega.mp3",
                "novamensagem.mp3",
                "viasete.mp3",
                "viasetenovaentrega.mp3",
              ],
            },
            {
              tipo: "diavencimento",
              p: "array",
              array: Array.from(Array(30).keys(), (n) => n + 1),
            },
          ],

          organizado: this.organizado(),

          campos: [
            {
              ref: "id",
              label: "Código Central",
              type: "justlabel",
              placeHolder: "Código Central",
            },
            {
              ref: "cnpj",
              label: "CNPJ",
              type: "text",
              placeHolder: "CNPJ",
              required: true,
            },
            {
              ref: "razaosocial",
              label: "Razão Social",
              type: "text",
              placeHolder: "Razão Social",
              required: true,
            },
            {
              ref: "nomefantasia",
              label: "Nome Fantasia",
              type: "text",
              placeHolder: "Nome Fantasia",
              required: true,
            },
            {
              ref: "senhapadrao",
              label: "Senha padrão para convênios",
              type: "text",
              placeHolder: "Senha padrão para convênios",
              required: true,
            },
            {
              ref: "telefone",
              label: "Telefone",
              type: "telefone",
              placeHolder: "Telefone",
              required: true,
            },
            {
              ref: "antecipar",
              label: "Antecipar Agendamento",
              type: "integer",
              placeHolder: "0",
            },
            {
              ref: "tempolimite",
              label: "Cancelamento depois de",
              type: "integer",
              placeHolder: "0",
              required: true,
            },
            {
              ref: "efichavepix",
              label: "Chave Pix (efi)",
            },
            {
              ref: "tipo_gateway",
              label: "Gateway de Pagamento",
              type: "select",
              listtype: "gateway",
              default: 0,
              required: true,
            },
            {
              ref: "ativo",
              label: "Central Ativo",
              type: "checkbox",
            },
            {
              ref: "pgseguro_app_email",
              label: "email PagSeguro",
              type: "text",
            },
            {
              ref: "pgseguro_app_sms",
              label: "sms PagSeguro",
              type: "text",
            },

            {
              ref: "encomendas",
              label: "Encomendas",
              type: "checkbox",
            },
            {
              ref: "transporte",
              label: "Transporte de passageiros",
              type: "checkbox",
            },
            {
              ref: "valor_franquia",
              label: "Valor da Franquia",
              type: "currency",
              default: 190,
              required: true,
            },
            {
              ref: "corridas_franquia",
              label: "Quantidade de Corridas da Franquia",
              type: "number",
              default: 300,
              placeHolder: "300",
              required: true,
            },

            {
              ref: "extra_franquia",
              label: "Valor extra da Franquia",
              type: "currency",
              default: 1,
              required: true,
            },
            {
              ref: "percent_franquia",
              label: "Percentual da Franquia",
              type: "percentage",
              default: 0,
              required: true,
            },
            {
              ref: "vencimento",
              label: "Vencimento",
              type: "select",
              listtype: "diavencimento",
              placeHolder: "0",
              default: 0,
              required: true,
            },
            {
              ref: "fechamento",
              label: "Fechamento",
              type: "select",
              listtype: "diavencimento",
              placeHolder: "0",
              default: 0,
              required: true,
            },

            {
              ref: "banco",
              label: "Banco",
              type: "select",
              listtype: "tabelabancos",
              required: true,
            },

            {
              ref: "tipoconta",
              label: "Tipo da Conta",
              type: "select",
              listtype: "tipoconta",
              required: true,
            },
            {
              ref: "numeroagencia",
              label: "Número da Agência",
              type: "agencia",
              banco: "banco",
              tabela: "tabelabancos",
              required: true,
            },
            {
              ref: "numeroconta",
              label: "Número da Conta",
              type: "conta",
              banco: "banco",
              tabela: "tabelabancos",
              required: true,
            },
            { ref: "obs_field_label", label: "Label da Observação", type: "text" },

            { ref: "obs_field_label_plural", label: "Label da Observação (plural)", type: "text" },

            {
              ref: "num_maximo_carros",
              label: "Quantidade de Carros Maximo na solicitação",
              type: "number",
              default: 1,
              placeHolder: "1",
              required: true,
            },

            { ref: "pix", label: "PIX", type: "select", listtype: "pix" },
            { ref: "chave", label: "Chave PIX", type: "text" },
            { ref: "subdominio", label: "Sub dominio Portal", type: "text" },

            {
              ref: "whatsapp",
              label: "Whatsapp (apenas números)",
              type: "whasapp",
              required: true,
            },
            {
              ref: "endereco",
              label: "Endereço",
              type: "jsonEndereco",
              campos: [
                {
                  ref: "cep",
                  label: "CEP",
                  type: "cep",
                  cepdados: {
                    uf: "uf",
                    cidade: "cidade",
                    bairro: "bairro",
                    rua: "rua",
                  },
                },
                { ref: "rua", label: "Rua", type: "text" },
                { ref: "email", label: "E-mail", type: "text" },
                { ref: "bairro", label: "Bairro", type: "text" },
                { ref: "cidade", label: "Cidade", type: "text" },
                { ref: "numero", label: "Número", type: "text" },
                { ref: "complemento", label: "Complemento", type: "text" },
                { ref: "celularcontato", label: "Celular", type: "telefone" },
                { ref: "telefonecontato", label: "Telefone", type: "telefone" },
              ],
              organizado: [
                [
                  { ref: "cep", tipo: "campo" },
                  { ref: "rua", tipo: "campo" },
                ],
                [
                  { ref: "numero", tipo: "campo" },
                  { ref: "cidade", tipo: "campo" },
                ],
                [
                  { ref: "bairro", tipo: "campo" },
                  { ref: "complemento", tipo: "campo" },
                ],
                [
                  { ref: "celularcontato", tipo: "campo" },
                  { ref: "telefonecontato", tipo: "campo" },
                ],
                [
                  { ref: "email", tipo: "campo" },
                  { ref: "site", tipo: "campo" },
                ],
              ],
            },
            {
              ref: "audio",
              label: "Audios App",
              type: "json",
              campos: [
                {
                  ref: "corrida",
                  label: "Nova Corrida",
                  type: "select",
                  default: "honk.mp3",
                  listtype: "audio",
                },
                {
                  ref: "corridaalt",
                  label: "Corrida Alterada",
                  type: "select",
                  default: "honk.mp3",
                  listtype: "audio",
                },
                {
                  ref: "disconect",
                  label: "Erro de Conexão",
                  type: "select",
                  default: "honk.mp3",
                  listtype: "audio",
                },
                {
                  ref: "message",
                  label: "Alerta",
                  type: "select",
                  default: "honk.mp3",
                  listtype: "audio",
                },
              ],
              organizado: [
                [
                  { ref: "corrida", tipo: "campo" },
                  { ref: "fc", tipo: "fct", function: () => this.ShowPlaySound(this.state.record.audio.corrida) },
                ],
                [
                  { ref: "corridaalt", tipo: "campo" },
                  { ref: "fa", tipo: "fct", function: () => this.ShowPlaySound(this.state.record.audio.corridaalt) },
                ],                
                [
                  { ref: "disconect", tipo: "campo" },
                  { ref: "fd", tipo: "fct", function: () => this.ShowPlaySound(this.state.record.audio.disconect) },
                ],
                [
                  { ref: "message", tipo: "campo" },
                  { ref: "fm", tipo: "fct", function: () => this.ShowPlaySound(this.state.record.audio.message) },
                ],
              ],
            },
            {
              ref: "aplicativocor",
              label: "Cores",
              type: "jsonCor",
              campos: [
                {
                  ref: "foreground",
                  label: "foreground",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "background",
                  label: "background",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "terceary",
                  label: "terceary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "quaternary",
                  label: "quaternary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "quintary",
                  label: "quintary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "sextary",
                  label: "sextary",
                  type: "color",
                  default: "#FFFFFF",
                },
              ],
              organizado: [
                [
                  { ref: "foreground", tipo: "texto", texto: "Cor 1" },
                  { ref: "background", tipo: "texto", texto: "Cor 2" },
                  { ref: "terceary", tipo: "texto", texto: "Cor 3" },
                ],
                [
                  { ref: "foreground", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "background", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "terceary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                ],

                [
                  { ref: "quaternary", tipo: "texto", texto: "Cor 4" },
                  { ref: "quintary", tipo: "texto", texto: "Cor 5" },
                  { ref: "sextary", tipo: "texto", texto: "Cor 6" },
                ],
                [
                  { ref: "quaternary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "quintary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "sextary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                ],
              ],
            },
            {
              ref: "appccor",
              label: "Cores",
              type: "jsonCor",
              campos: [
                {
                  ref: "foreground",
                  label: "foreground",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "background",
                  label: "background",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "terceary",
                  label: "terceary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "quaternary",
                  label: "quaternary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "quintary",
                  label: "quintary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "sextary",
                  label: "sextary",
                  type: "color",
                  default: "#FFFFFF",
                },
              ],
              organizado: [
                [
                  { ref: "foreground", tipo: "texto", texto: "Cor 1" },
                  { ref: "background", tipo: "texto", texto: "Cor 2" },
                  { ref: "terceary", tipo: "texto", texto: "Cor 3" },
                ],
                [
                  { ref: "foreground", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "background", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "terceary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                ],

                [
                  { ref: "quaternary", tipo: "texto", texto: "Cor 4" },
                  { ref: "quintary", tipo: "texto", texto: "Cor 5" },
                  { ref: "sextary", tipo: "texto", texto: "Cor 6" },
                ],
                [
                  { ref: "quaternary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "quintary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "sextary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                ],
              ],
            },
            {
              ref: "appmcor",
              label: "Cores",
              type: "jsonCor",
              campos: [
                {
                  ref: "foreground",
                  label: "foreground",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "background",
                  label: "background",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "terceary",
                  label: "terceary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "quaternary",
                  label: "quaternary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "quintary",
                  label: "quintary",
                  type: "color",
                  default: "#FFFFFF",
                },
                {
                  ref: "sextary",
                  label: "sextary",
                  type: "color",
                  default: "#FFFFFF",
                },
              ],
              organizado: [
                [
                  { ref: "foreground", tipo: "texto", texto: "Cor 1" },
                  { ref: "background", tipo: "texto", texto: "Cor 2" },
                  { ref: "terceary", tipo: "texto", texto: "Cor 3" },
                ],
                [
                  { ref: "foreground", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "background", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "terceary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                ],

                [
                  { ref: "quaternary", tipo: "texto", texto: "Cor 4" },
                  { ref: "quintary", tipo: "texto", texto: "Cor 5" },
                  { ref: "sextary", tipo: "texto", texto: "Cor 6" },
                ],
                [
                  { ref: "quaternary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "quintary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                  { ref: "sextary", tipo: "campo", hidelabel: true, default: "#FFFFFF" },
                ],
              ],
            },
            { ref: "appicon", label: "Icone", type: "foto64" },
            { ref: "svgportal", label: "Svg Login", type: "text" },
            { ref: "svgbackground", label: "Background Svg Login", type: "foto64", maxWidth: 2000, maxheight: 2000 },
            {
              ref: "driverjson",
              label: "Configuração Aplicativo Motorista",
              type: "json",
              campos: [
                { ref: "appId", label: "appId (firebase)", type: "text" },
                { ref: "pacote", label: "pacote loja", type: "text" },
                { ref: "termo", label: "Identificador dos termos", type: "text" },
                { ref: "appTitle", label: "Título app longo", type: "text" },
                { ref: "appTitlep", label: "Título app curto", type: "text" },
                { ref: "temUrbs", label: "Tem Urbs?", type: "checkbox", default: false },
                { ref: "showValor", label: "Mostra Valor?", type: "checkbox", default: false },
                { ref: "valorsempre", label: "Mostra Valor SEMPRE?", type: "checkbox", default: false },
                { ref: "chatsempre", label: "Mostra Chat SEMPRE?", type: "checkbox", default: false },
                { ref: "compraDeposito", label: "Compra creditos por depósito?", type: "checkbox", default: false },
                { ref: "destinosjuntos", label: "Mantem os destinos Juntos?", type: "checkbox", default: false },
                { ref: "novolayout", label: "Usa novo Layout?", type: "checkbox", default: false },
                { ref: "producao", label: "Ambiente de producao?", type: "checkbox", default: false },
                { ref: "icone", label: "icone", type: "text" },
                { ref: "iconTaxi", label: "icone do carro", type: "text" },
                { ref: "imageloading", label: "image de loading", type: "text" },
                { ref: "tipocorrida", label: "Texto tipo da corrida", type: "text" },
                { ref: "tipocorridaplural", label: "Texto tipo da corrida (plural)", type: "text" },
                { ref: "tipocarroDefault", label: "Tipo de veiculo padrão no precadastro", type: "text" },
                { ref: "tipocarro", label: "Tipo de veiculos no precadastro", type: "array" },
              ],
            },
            {
              ref: "clientjson",
              label: "Configuração Aplicativo Cliente",
              type: "json",
              campos: [
                { ref: "appId", label: "appId (firebase)", type: "text" },
                { ref: "pacote", label: "pacote loja", type: "text" },
                { ref: "termo", label: "Identificador dos termos", type: "text" },
                { ref: "appTitle", label: "Título app longo", type: "text" },
                { ref: "appTitlep", label: "Título app curto", type: "text" },
              ],
            },
          ],

          table: {
            colunas: [
              {
                Header: "Razão Social",
                accessor: "razaosocial",
              },
              {
                accessor: "nomefantasia",
                Header: "Nome Fantasia",
              },
              {
                Header: "Encomendas",
                accessor: "encomendas",
                width: 100,
                Cell: (row) => (
                  <div
                    style={{
                      width: "16%",
                      height: "76%",
                      margin: "0 auto",
                      backgroundColor: row.original.encomendas ? "green" : "red",
                      borderRadius: "2px",
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) => (
                  <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : ""}>
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
                ),
              },
              {
                Header: "Transporte",
                accessor: "transporte",
                width: 100,
                Cell: (row) => (
                  <div
                    style={{
                      width: "16%",
                      height: "76%",
                      margin: "0 auto",
                      backgroundColor: row.original.transporte ? "green" : "red",
                      borderRadius: "2px",
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) => (
                  <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : ""}>
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
                ),
              },
              {
                Header: "Ativo",
                accessor: "ativo",
                width: 100,
                Cell: (row) => (
                  <div
                    style={{
                      width: "16%",
                      height: "76%",
                      margin: "0 auto",
                      backgroundColor: row.original.ativo ? "green" : "red",
                      borderRadius: "2px",
                    }}
                  />
                ),
                filterMethod: (filter, row) => {
                  if (filter.value === "") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id];
                  }
                  return !row[filter.id];
                },
                Filter: ({ filter, onChange }) => (
                  <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : ""}>
                    <option value="">Todos</option>
                    <option value="true">Ativado</option>
                    <option value="false">Desativado</option>
                  </select>
                ),
              },
              {
                Header: "PagSeguro",
                accessor: "pgseguro_app_created",
                width: 100,
                Cell: (row) => (
                  <div
                    style={{
                      width: "16%",
                      height: "76%",
                      margin: "0 auto",
                      backgroundColor: !!row.original.pgseguro_app_created ? "green" : "red",
                      borderRadius: "2px",
                    }}
                  />
                ),
              },
            ],
          },
        }}
      />
    );
  }
}

export default Central;
